@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


body {
  font-family: 'Fira Sans Condensed', sans-serif;
  background-color: black;
  color: white;
  margin: 0;
  padding: 0;
}



/* LOGIN */

.logo-deeptimize {
  height: 40%;
  width: 40%;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: -50px;
  height: 100vh; /* Cette propriété est optionnelle et peut être ajustée en fonction de votre mise en page */
}

.title-login {
  margin-top: -50px;
  margin-bottom: 100px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px; /* Ajustez la largeur si nécessaire */
}

.form-group {
  margin-bottom: 25px; /* Espace entre les champs du formulaire */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

label {
  margin-bottom: 5px; /* Espace entre le label et l'input */
}

input {
  width: 100%;
  padding: 8px 0;
  border: none;
  border-bottom: 2px solid #ccc;
  box-sizing: border-box;
  background: transparent;
  min-width: 300px;
  outline: none;
  color: white;
}

input:focus {
  border-bottom: 2px solid #77E16F;
}

.nav-link {
  margin-bottom: 20px; /* Espace entre le lien et le bouton de connexion */
  color: #77E16F;
  text-decoration: none;
  font-size: 22px;
}

.nav-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.login-button {
  padding: 15px 50px;
  background-color: #ffffff;
  color: black;
  font-weight: 600;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 25px;
}

.login-button:hover {
  background-color: #000000; /* Couleur au survol */
  color: white;
}

.password-input-container {
  display: flex;
  align-items: center;
}

.toggle-password-button {
  margin-left: 10px;
  padding: 5px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: blue;
  text-decoration: underline;
}


/* END LOGIN */


/* NAVBAR */

.navbar-logo {
  height: 20%;
  width: 20%;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}


/* END NAVBAR */


/* HELP */

/* styles.css */

.help-title {
  text-align: center;
  margin-bottom: 50px; 
}

.help-text {
  margin-left: 100px; 
  color: #ffffff; 
  font-size: 16px; 
  line-height: 1.6; 
}

.no-record-title {
  text-align: center;
  margin-top: 50px; 
  font-size: 2.5rem;
  color: #333;
}

.no-record-text {
  display: inline-block;
  font-weight: bold;
  color: #ffffff;
}

.qa-container {
  margin-top: 20px; 
}

.qa-item {
  margin-bottom: 80px; 
}

.blue-text {
  color: blue;
}


/* END HELP */


/* UPLOAD */

.progress-bar-container {
  width: 100%;
  height: 20px;
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-top: 10px;
}

.progress-bar {
  height: 100%;
  background-color: #007bff;
  border-radius: 4px;
  transition: width 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.upload-text {
  color: white;
  text-align: center;
}

.progress-text {
  color: white;
  position: absolute;
  left: 5px;
}


.upload-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75vh; /* Ajustez la hauteur comme nécessaire */
}

.yellow-text {
  color: yellow;
}

.upload-box {
  width: 1000px; /* Ajustez la largeur comme nécessaire */
  height: 300px; /* Ajustez la hauteur comme nécessaire */
  background-color: #fff;
  border: 2px dashed #ccc; /* Bordure pointillée grise */
  display: flex;
  color: #000000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  border-radius: 25px;
}

.drop-text {
  font-size: 40px;
  margin-bottom: -5px;
  margin-top: 15px;
}

.upload-button {
  margin-top: 20px; /* Espace supplémentaire au-dessus du bouton */
  padding: 20px 80px;
  font-size: 26px;
  background-color: #000000; /* Couleur de fond du bouton */
  color: yellow; /* Couleur du texte du bouton */
  border: none;
  cursor: pointer;
  border-radius: 20px;
}

.option {
  align-items: center;
  justify-content: center;
  display: flex;
}

.optionbar {
  width: 250px;
  margin-bottom: 20px;
  text-align: center;
  font-size: large;
  height: 30px;
  background-color: #000000;
  color: white;
}

.analysis-option {
  background-color: #000000;
  color: white;
  width: 200px;
  text-align: center;
  border-radius: 20px;
}

.upload-box {
  width: 1000px; /* Ajustez la largeur comme nécessaire */
  height: 300px; /* Ajustez la hauteur comme nécessaire */
  background-color: #fff;
  border: 2px dashed #ccc; /* Bordure pointillée grise */
  padding: 20px;
  text-align: center;
  position: relative;
  display: flex;
  color: #000000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
}

.upload-box.drag-active {
  border-color: #3498db;
  background-color: #f0f8ff;
  color: #000000;
}


.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  color: #000000;
  text-align: center;
}

.popup-content h2 {
  margin-top: 0;
}

.popup-content button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #000000;
  color: yellow;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button {
  margin-top: 0px; /* Espace supplémentaire au-dessus du bouton */
  padding: 20px 80px;
  font-size: 22px;
  font-weight: 700;
  background-color: #ffffff; /* Couleur de fond du bouton */
  color: rgb(0, 0, 0); /* Couleur du texte du bouton */
  border: none;
  cursor: pointer;
  border-radius: 20px;
}

/* END UPLOAD */


/* DATA RECORD */

.data-title {
  text-align: center;
  font-size: 50px;
  margin-top: 10px;
  margin-bottom: 50px;
}

.green-text {
  color: #77E16F;
}

.div-image {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 70px; */
}


.card-image {
height: 100px;

}

.result-text {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 10px; 
}

.download-buttons {
  margin-top: 20px;
}

.download-button {
  background-color: #4CAF50; /* Green */
  color: white;
  padding: 10px 20px;
  margin: 5px;
  border: none;
  cursor: pointer;
}

.download-button:hover {
  background-color: #45a049;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* espace entre les cartes */
}

.card {
  padding: 20px;
  border-radius: 8px;
  width: calc(20% - 40px); /* Trois cartes par ligne avec espace */
  box-sizing: border-box;
  margin: 10px;
}

.card-item {
  margin: 10px;
  width: calc(20% - 20px); /* 20% width for 5 cards per row, minus margin */
}

.card-image {
  width: 100%;
}

.download-button {
  width: 100%;
  border-radius: 10px;
}


/* styles.css ou votre fichier de styles */
.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3); /* Couleur et épaisseur des bordures */
  border-radius: 50%;
  border-top: 4px solid #ffffff; /* Couleur de la bordure supérieure */
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite; /* Animation de rotation */
  margin: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.progress-bar-container {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-top: 20px;
}

.progress-bar {
  height: 20px;
  background-color: #4caf50;
  width: 0;
  border-radius: 5px;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.footer-left,
.footer-center,
.footer-right {
  flex: 1;
  text-align: center;
}

.footer-left {
  text-align: left;
}

.footer-right {
  text-align: right;
}

.footer-container a {
  text-decoration: none;
}

.footer-container a:hover {
  text-decoration: underline;
}


/* PAGINATION */

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
  padding: 8px 12px;
  border: 1px solid #ddd;
  background-color: #f1f1f1;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #ddd;
}

.pagination button.active {
  background-color: #4caf50;
  color: white;    
  border-color: #4caf50;
}

.pagination button:disabled {
  background-color: #e9e9e9;
  cursor: not-allowed;
}